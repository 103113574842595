@import '~antd/dist/antd.dark.css';

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262626;
  font-size: 16px;

  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #595959;
  --gray-9: #262626;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-8);
  border-radius: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-layout {
  background-color: transparent;
}

.ant-layout-content {
  max-width: 1280px;
  width: 100%;
  margin: auto;
  padding-bottom: 32px;
}

.form-list-item {
  max-width: 500px;
}

.ant-form-item-control-input {
  min-height: 0 !important;
}

.service-card {
  width: 298px;
  display: flex;
  flex-direction: column;
}

.header-menu {
  border: none !important;
  position: relative;
  overflow: hidden;
}

.service-card .ant-card-cover {
  height: 300px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.service-card .ant-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.service-card .ant-card-body::after {
  display: none;
}

.small-container {
  max-width: 700px;
  width: 100%;
  margin: auto;
}

.quill-custom {
  display: flex;
  flex-direction: column-reverse;
}

.quill-custom .ql-toolbar.ql-snow {
  border: none;
}

.quill-custom .ql-container.ql-snow {
  border: 1px solid #595959 !important;
  border-radius: 4px;
  min-height: 135px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quill-custom .ql-container .ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  background-color: var(--gray-9);
}

.service-tabs .ant-tabs-nav {
  margin: 0;
}

.arrow-button {
  width: 40px;
  height: 40px;
  border-color: #165996 !important;
  transition: all 0.3s !important;
}

.arrow-button:hover {
  border: 1px solid #165996;
}

.arrow-button:focus {
  border: 1px solid #165996;
}

.arrow-button:active {
  border: 1px solid #165996;
}

.service-gallery-upload .ant-upload-list-picture-card-container {
  width: auto;
  height: auto;
  margin: 0;
}

.service-gallery-upload .ant-upload-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.preview-image-wrapper {
  width: 135px;
  height: 90px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.preview-image-wrapper img,
video {
  max-width: 100%;
  max-height: 100%;
}

.authorization-form {
  width: 470px;
}

/* backgrounds */
.bg-neutral {
  background-color: #1f1f1f;
}
.bg-gray-9 {
  background: var(--gray-9);
}
.bg-gray-8 {
  background: var(--gray-8);
}
/* backgrounds */

/* rounded */

.rounded-4 {
  border-radius: 4px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}
/* rounded */

/* common */
.overflow-hidden {
  overflow: hidden;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
/* common */

/* paddings */
.p-0 {
  padding: 0;
}

.p-16 {
  padding: 16px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-48 {
  padding-left: 48px;
}

.pt-8 {
  padding-top: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.p-24 {
  padding: 24px;
}

.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ph-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.pb-40 {
  padding-bottom: 40px;
}
/* paddings */

/* margins */
.m-0 {
  margin: 0 !important;
}

.m-reset {
  margin: 0;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

/* Я эту грязь делать не хотел, честно, но это Ant ;( */
.mr-16-important {
  margin-right: 16px !important;
}

.mr-40 {
  margin-right: 40px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mv-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.-mt-24 {
  margin-top: -24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-40 {
  margin-bottom: 40px;
}
/* margins */

/* display */
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}
/* display */

/* flex */
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-items-start {
  align-items: flex-start;
}

.flex-items-end {
  align-items: flex-end;
}

.flex-items-center {
  align-items: center;
}

.flex-items-baseline {
  align-items: baseline;
}

.flex-items-stretch {
  align-items: stretch;
}
.user-select-none {
  user-select: none;
}
.flex-content-start {
  align-content: flex-start;
}

.flex-content-end {
  align-content: flex-end;
}

.flex-content-center {
  align-content: center;
}

.flex-content-between {
  align-content: space-between;
}

.flex-content-around {
  align-content: space-around;
}

.flex-content-stretch {
  align-content: stretch;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-self-auto {
  align-self: auto;
}

.flex-self-start {
  align-self: flex-start;
}

.flex-self-end {
  align-self: flex-end;
}
.text-left {
  text-align: left;
}

.flex-self-center {
  align-self: center;
}

.flex-self-baseline {
  align-self: baseline;
}

.flex-self-stretch {
  align-self: stretch;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}
/* flex */

/* colors */
.text-white {
  color: #ffffff !important;
}

.text-gray-5 {
  color: #d9d9d9 !important;
}

.text-gray-6 {
  color: var(--gray-6) !important;
}

.text-gray-7 {
  color: var(--gray-7) !important;
}

.text-gray-8 {
  color: var(--gray-8) !important;
}

.text-red-6 {
  color: #f5222d !important;
}
/* colors */

/* line-height */
.line-height-1 {
  line-height: 1;
}
/* line-height */

/* text-align */
.text-align-center {
  text-align: center;
}
/* text-align */

/* border */
.border-none {
  border: none;
}

.active-border {
  border: 1px solid #1890ff;
}
/* border */

/* width */
.w-100vw {
  width: 100vw;
}
.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}
.w-150px {
  max-width: 150px;
}
.w-200px {
  max-width: 200px;
}
.w-250px {
  max-width: 250px;
}

.tag + .tag::before {
  display: inline-block;
  white-space: pre;
  content: ', ';
}
/* width */

/* height */
.h-100vh {
  height: 100vh;
}
/* height */

.relative {
  position: relative;
}

.ant-select-disabled {
  opacity: 0.4;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 2;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  max-width: 600px;
}

.required::before {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-card-body {
  padding: 16px;
}
