.topContainer > div {
  align-items: center;
}

.datePicker {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
  background: var(--gray-9);
}

:global(.ant-picker-time-panel-column) {
  &:nth-child(2) {
    display: none; /* Hides the minutes selection column */
  }
}
