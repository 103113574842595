.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #73d13d;
}

.status.offline {
  background-color: #f5222d;
}
