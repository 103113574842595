.itemContainer {
  margin-bottom: 24px;
  padding: 8px 0 0 0;
}

.url {
  padding: 8px 16px 0 16px;
  border-top: 1px solid #434343;
}

.url > div > div:first-child {
  padding: 0;
}

.url > div > div:first-child > label {
  color: #8c8c8c;
}
