@media screen and (max-width: 1222px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .container > div:last-child {
    width: 100%;
    justify-content: center;
    padding: 12px 0;
  }
}
