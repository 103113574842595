.loadingWrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.loadingComponent {
  width: 100%;
  height: 100%;
  position: relative;
  filter: grayscale(1);
  opacity: 0.6;
}

.spinContainer {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.hiddenSpinner {
  display: none;
}

.hiddenContainer {
  filter: none;
  opacity: 1;
}
