.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.description *:not(:first-child) {
  display: none;
}

.separator {
  position: relative;
  border-top: 1px solid #262626;
  height: 1px;
  left: -25px;
  width: calc(100% + 50px);
  margin: 0 0 12px 0;
}
