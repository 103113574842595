.cityContainer > div:first-child {
  width: 100%;
  justify-content: flex-end;
}

.cityContainer > div:first-child > div > div > div {
  gap: 12px;
  display: flex;
  flex-direction: column;
}
